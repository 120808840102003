import React, {useState} from "react";
import { Col, Container, Nav, Row } from 'react-bootstrap'
import logo from './images/logo.png'
import img2 from './images/2.svg'
import img1 from './images/1.svg'
import img3 from './images/3.svg'
import img4 from './images/4.svg'
import img5 from './images/5.png'
// import bg from './images/bg.svg'
import './common.css'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const Home = () => {
    const [fullscreen, setFullscreen] = useState(true);
    const [show, setShow] = useState(false);
    const [showMission, setShowMission] = useState(false);
    const [showValues, setShowValues] = useState(false);
    const [showContact, setShowContact] = useState(false);
    // function handleShowMission() {
    //     setFullscreen();
    //     setShow(false);
    //     setShowMission(true);
    // }
    function handleShow(type) {
        setShow(true)
        if(type=="mission"){
            setShowMission(true);
            setShowValues(false)
            setShowContact(false)

        }
        else if(type=="values"){
            setShowMission(false)
            setShowContact(false)
            setShowValues(true)
        }
        else if(type=="contact"){
            setShowMission(false)
            setShowContact(true)
            setShowValues(false)
        }

    }
    return(
    <div className="content-wpr">
        <div className="content-main">

            <div className="content-header">
                <Row>
                    <Col xs={4} md={4} className="header-left">
                        <div className="logo">
                            <img src={logo} />
                            <p>ARTS.SPORTS.MUSIC</p>
                        </div>
                    </Col>
                    <Col xs={8} md={8} className="header-right">
                        <a onClick={() => handleShow('mission')}>Our Mission</a>
                        <a onClick={() => handleShow('values')}>Our Values</a>
                        <button onClick={() => handleShow('contact')} className="btn">Contact Us</button>
                    </Col>
                </Row>
            </div>
            <div className="content-body">
                <Row>
                    <Col xs={12} xl={5} md={6} sm={6} className="content-body-col">
                        <h1>ARTBRATS</h1>
                        <p>
                            Artbrats is the premier, one of a kind, NFT marketplace in India. We are the 'House of Art', created 
                            by none other than the artists themselves. We offer a unique platform for artists to create and sell
                            their NFTs, and for collectors to buy and trade them. 
                        </p>
                        <p>
                            We believe that art should be accessible to everyone, and that NFTs are the future of Art ownership. 
                            Our platform allows artists to sell their work directly to collectors, without the need for a middleman.
                        </p>
                        <div className="bg-img-wrp">
                            <img className="bg-img-1" src={img3} />
                            <img className="bg-img-2" src={img1} />
                            <img className="bg-img-3" src={img2} />
                        </div>
                    </Col>
                </Row>
                
            </div>
            


            <Modal show={show} onHide={() => setShow(false)}>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <div className="content-body">
                        {showMission &&
                        <>
                            <h1>Our Mission</h1>
                            <p>
                            Our mission is to empower artists and collectors to participate in the NFT revolution. We believe that
                            NFTs have the potential to change the way art is created, sold, and collected. We are committed to
                            providing a platform that allows artists to share their work with the world, and for collectors to find and
                            own unique and valuable pieces of art.
                            </p>
                        </>
                        }
                        {showValues &&
                        <>
                            <h1>Our Values</h1>
                            <p>
                                <b>Authenticity :</b> We believe that art should be authentic. We only work with artists who are passionate 
                                about their work, and who are committed to creating high-quality art.
                            </p>
                            <p>
                                <b>Transparency :</b> We believe that transparency is important. We are open about our fees, and we provide
                                clear information about how NFTs work.
                            </p>
                            <p>
                                <b>Community :</b> We believe that art is best enjoyed in a community. We are committed to building a
                                community of artists, collectors, and enthusiasts who can share their passion for art.
                            </p>
                            <p>
                                We are excited to be a part of the NFT revolution, and we hope you will join us. Whether you are an artist
                                or a collector, we have something for you.
                            </p>
                        </>
                        }
                        {showContact &&
                        <>
                            <h1>Contact Us</h1>
                            <div className="contact-wpr">
                                <div>
                                    <span><i className="fa fa-phone"></i> <b>Call Us</b></span>
                                    <p>+91 80-49104519</p>
                                </div>
                                <div>
                                    <span><i className="fa fa-envelope"></i> <b>Email Us</b></span>
                                    <p>info@artbrats.com</p>
                                </div>
                            </div>
                            <div className="social-links">
                                <p>Follow Us</p>
                                <ul>
                                    <li><a href="https://instagram.com/itw_arbrats?igshid=MzRlODBiNWFlZA==" target="_blank"><i className="fa fa-instagram"></i></a></li>
                                    {/* <li><a href="#"><i className="fa fa-linkedin"></i></a></li> */}
                                    <li><a href="https://www.facebook.com/profile.php?id=100093249647059" target="_blank"><i className="fa fa-facebook"></i></a></li>
                                </ul>

                            </div>
                        </>
                        }
                    </div>
                    <div className="img-wrp">
                        <img className="img-2" src={img2} />
                        <img className="img-1" src={img3} />
                        <img className="img-3" src={img5} />
                        <img className="img-4" src={img4} />
                    </div>
                </Modal.Body>
            </Modal>

           
        </div>
        <div className="content-footer">
                <p>© 2023 <b>ARTBRATS</b>, ALL RIGHTS RESERVED</p>
            </div>
    </div>
        
    )
}

export default Home